import { InfoCircledIcon } from '@radix-ui/react-icons'
import * as RadixTooltip from '@radix-ui/react-tooltip'
import TooltipWithClickToOpen from './tooltip-with-click-to-open'
import * as styles from './tooltip.css.ts'

interface TooltipProps {
  children: React.ReactNode
}

export const Tooltip = ({ children }: TooltipProps) => {
  return (
    <TooltipWithClickToOpen>
      <RadixTooltip.Trigger>
        <InfoCircledIcon className={styles.infoIcon} />
      </RadixTooltip.Trigger>
      <RadixTooltip.Portal>
        <RadixTooltip.Content className={styles.tooltip} sideOffset={3}>
          {children}
        </RadixTooltip.Content>
      </RadixTooltip.Portal>
    </TooltipWithClickToOpen>
  )
}
