import { createContext, useContext, useEffect, useState } from 'react'

export type ThemeContextType = {
  theme: 'light' | 'dark'
  handleToggleTheme: () => void
}

export const ThemeContext = createContext<ThemeContextType>({ theme: 'dark', handleToggleTheme: () => {} })

export const useTheme = (initialTheme?: ThemeContextType['theme']) => {
  const [theme, setTheme] = useState<ThemeContextType['theme']>(initialTheme || 'dark')

  useEffect(() => {
    if (initialTheme) {
      setTheme(initialTheme)
      document.documentElement.setAttribute('data-bs-theme', initialTheme)
      localStorage.setItem('currentTheme', initialTheme)
    } else {
      const storedTheme = localStorage.getItem('currentTheme')
      if (storedTheme === 'light' || storedTheme === 'dark') {
        setTheme(storedTheme)
        document.documentElement.setAttribute('data-bs-theme', storedTheme)
      } else {
        localStorage.setItem('currentTheme', 'dark')
      }
    }
  }, [initialTheme])

  const handleToggleTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark'
    setTheme(newTheme)
    localStorage.setItem('currentTheme', newTheme)
    document.documentElement.setAttribute('data-bs-theme', newTheme)
  }

  return { theme, setTheme, handleToggleTheme }
}

export const useThemeContext = () => useContext(ThemeContext)
