import { Link } from 'react-router-dom'
import { useEnvironmentData } from '../utils/use-environment-data'
import { useThemeContext } from '../utils/use-theme'
import {
  AnalysisStatusLogsByRepositoryId,
  PullRequest,
  RepositoryActivation,
  RepositoryWithInstallationId,
} from '../utils/user-platform-api-schemas'
import styles from './coverage-table.module.css'
import { RepoStatus, calculateRepositoryStatus, sortStatuses } from './repository-status'
import { TableBodySkeleton } from './table-body-skeleton'

type CoverageTableProps = {
  repositories: RepositoryWithInstallationId[]
  repositoriesActivation: RepositoryActivation[]
  accountPRs: PullRequest[]
  analysesByRepository: AnalysisStatusLogsByRepositoryId[]
}

export function CoverageTable({
  repositories,
  repositoriesActivation,
  accountPRs,
  analysesByRepository,
}: CoverageTableProps) {
  const environmentData = useEnvironmentData()

  return (
    <>
      <div style={{ marginBottom: '20px' }}>
        <div>
          <h4 className="h-4 installation-header">Coverage</h4>
        </div>
        <div className="row">
          <div className={`col-md-8 d-flex align-items-center paragraph ${styles.tableDescription}`}>
            Repositories monitored by {environmentData.githubAppName}.
          </div>
        </div>
      </div>
      <table className={`table table-borderless table-hover table-striped-custom ${styles.table}`}>
        <ListRepoHeading />
        <TableBody
          repositories={repositories}
          repositoriesActivation={repositoriesActivation}
          allPixeebotPRs={accountPRs}
          analysesByRepository={analysesByRepository}
        />
      </table>
      <p>
        <small>
          <a
            href={`https://github.com/apps/pixeebot/installations/new`}
            target="_blank"
            className="btn-no-border-outline small-bold"
            data-toggle="tooltip"
            data-placement="top"
          >
            <span className="plus-icon">+</span> Add repository
          </a>
        </small>
      </p>
    </>
  )
}

function ListRepoHeading() {
  const environmentData = useEnvironmentData()

  return (
    <thead>
      <tr>
        <th scope="col" className="column-header ps-4" style={{ width: '75%' }}>
          REPOSITORY
        </th>
        <th
          scope="col"
          className="column-header"
          style={{ width: '25%' }}
          title="pixeebot analysis status of the latest code on your default branch."
        >
          <img src={environmentData.logoIconHref} alt="" height={18} /> &nbsp; STATUS
        </th>
      </tr>
    </thead>
  )
}

type TableBodyProps = {
  repositories: RepositoryWithInstallationId[]
  repositoriesActivation: RepositoryActivation[]
  allPixeebotPRs: PullRequest[]
  analysesByRepository: AnalysisStatusLogsByRepositoryId[]
}

function TableBody({ repositories, repositoriesActivation, allPixeebotPRs, analysesByRepository }: TableBodyProps) {
  if (repositories.length === 0) return <TableBodySkeleton rowCount={7} columnCount={2} rowHeightInPixels={43} />

  const repositoriesWithData = repositories.map(repository => {
    const repositoryActivation = repositoriesActivation.find(
      repositoryActivation => repositoryActivation.repository_id === repository.id
    )

    const repositoryPixeebotPRs = allPixeebotPRs.filter(
      pullRequest => pullRequest.repository_url === `https://api.github.com/repos/${repository.full_name}`
    )

    const analyses =
      analysesByRepository.find(({ repository_id }) => repository_id === repository.id)?.analysis_status_logs ?? []

    const repositoryStatus = calculateRepositoryStatus({
      repositoryPixeebotOpenPRs: repositoryPixeebotPRs.filter(pr => pr.state === 'open'),
      analyses,
      repositoryActivation,
    })

    return {
      repository,
      repositoryActivation,
      repositoryPixeebotPRs,
      repositoryStatus,
    }
  })

  return (
    <tbody>
      {repositoriesWithData
        .sort(({ repositoryStatus: repositoryStatusA }, { repositoryStatus: repositoryStatusB }) =>
          sortStatuses(repositoryStatusA.status, repositoryStatusB.status)
        )
        .map(({ repository, repositoryActivation, repositoryPixeebotPRs, repositoryStatus }) => (
          <RepoRow
            key={repository.id}
            repository={repository}
            repositoryActivation={repositoryActivation}
            repositoryPixeebotPRs={repositoryPixeebotPRs}
            statusMetadata={repositoryStatus}
          />
        ))}
    </tbody>
  )
}

type RepoRowProps = {
  repository: RepositoryWithInstallationId
  repositoryActivation?: RepositoryActivation
  repositoryPixeebotPRs: PullRequest[]
  statusMetadata: ReturnType<typeof calculateRepositoryStatus>
}

function RepoRow({ repository, repositoryActivation, repositoryPixeebotPRs, statusMetadata }: RepoRowProps) {
  return (
    <tr>
      <td className="ps-4">
        <RepoName repository={repository} repositoryActivation={repositoryActivation} />
      </td>
      <td>
        <RepoStatus
          repository={repository}
          repositoryPixeebotOpenPRs={repositoryPixeebotPRs.filter(pr => pr.state === 'open')}
          statusMetadata={statusMetadata}
        />
      </td>
    </tr>
  )
}

type RepoNameProps = {
  repository: RepositoryWithInstallationId
  repositoryActivation?: RepositoryActivation
}

function RepoName({ repository, repositoryActivation }: RepoNameProps) {
  const { theme } = useThemeContext()

  const isActive = (repositoryActivation?.pixee_approved && repositoryActivation.user_activated) ?? false

  return (
    <Link
      className={`paragraph align-middle ${styles.repositoryLink}`}
      style={{
        color: isActive
          ? theme === 'light'
            ? 'var(--neutral90)'
            : 'var(--neutral10)'
          : theme === 'light'
            ? 'var(--neutral50)'
            : 'var(--neutral30)',
        pointerEvents: isActive ? undefined : 'none',
      }}
      to={`/context/${repository.full_name}`}
    >
      {repository.name}
    </Link>
  )
}
