import { flexRender, Table as ReactTable, RowData } from '@tanstack/react-table'
import classNames from 'classnames'
import { PaginationControls } from './pagination-controls.tsx'
import { TableBodySkeleton } from './table-body-skeleton.tsx'
import * as styles from './table.css.ts'

declare module '@tanstack/table-core' {
  interface ColumnMeta<TData extends RowData, TValue> {
    width: string
  }

  // TODO: Open an issue on the react-table repo to allow typing meta property as required instead of optional
  // interface ColumnDefBase<TData extends RowData, TValue> {
  //   meta: ColumnMeta<TData, TValue>
  // }
}

export function Table<T>({
  table,
  isLoading,
  pagination,
  setPagination,
  tableLabel,
}: {
  table: ReactTable<T>
  isLoading: boolean
  pagination?: { pageIndex: number; pageSize: number }
  setPagination?: (pagination: { pageIndex: number; pageSize: number }) => void
  tableLabel?: string
}) {
  const data = table.options.data
  const columns = table.options.columns
  const rows = table.getRowModel().rows

  return (
    <table className={styles.table}>
      <colgroup>
        {columns.map((column, index) => (
          <col key={index} style={{ width: column.meta?.width }} />
        ))}
      </colgroup>
      <thead className={styles.tableHead}>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => {
              return (
                <th key={header.id} className={classNames(styles.columnHeader, styles.cell)}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              )
            })}
          </tr>
        ))}
      </thead>
      {isLoading ? (
        <TableBodySkeleton
          rowCount={7}
          columnCount={columns.length}
          rowHeightInPixels={43}
          label={`Loading ${tableLabel} data...`}
        />
      ) : (
        <>
          <tbody>
            {data.length === 0 ? (
              <tr>
                <td className={classNames(styles.cell, styles.emptyStateCell)} colSpan={columns.length}>
                  <div className={styles.emptyStateText}>No data yet, stay tuned</div>
                </td>
              </tr>
            ) : rows.length === 0 ? (
              <tr>
                <td className={classNames(styles.cell, styles.emptyStateCell)} colSpan={columns.length}>
                  <div className={styles.emptyStateText}>No results found</div>
                </td>
              </tr>
            ) : (
              rows.map(row => (
                <tr key={row.id} className={styles.row}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id} className={styles.cell}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
          {pagination && setPagination && tableLabel && (
            <tfoot>
              <tr>
                <th colSpan={columns.length} className={styles.paginationCell}>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                    <PaginationControls
                      tableLabel={tableLabel}
                      pageIndex={pagination.pageIndex}
                      pageSize={pagination.pageSize}
                      rowCount={table.getPrePaginationRowModel().rows?.length}
                      onChangePageSize={value => setPagination({ pageIndex: 0, pageSize: value })}
                      onNextPage={() =>
                        setPagination({ pageIndex: pagination.pageIndex + 1, pageSize: pagination.pageSize })
                      }
                      onPreviousPage={() =>
                        setPagination({ pageIndex: pagination.pageIndex - 1, pageSize: pagination.pageSize })
                      }
                    />
                  </div>
                </th>
              </tr>
            </tfoot>
          )}
        </>
      )}
    </table>
  )
}
