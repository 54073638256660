import { PropsWithChildren, useMemo } from 'react'
import { Footer } from '../components/footer'
import { NavigationBar } from '../components/navigation-bar'
import { NavigationBarStub } from '../components/navigation-bar-stub'
import { useOutletData } from '../routes/root'
import { ThemeContext, ThemeContextType, useTheme } from './use-theme'
import * as styles from './withLayout.css'

export const WithTheme = ({
  initialTheme,
  children,
}: PropsWithChildren<{ initialTheme?: ThemeContextType['theme'] }>) => {
  const { theme, handleToggleTheme } = useTheme(initialTheme)

  const contextValue = useMemo(
    () => ({
      theme,
      handleToggleTheme,
    }),
    [theme, handleToggleTheme]
  )

  return <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>
}

export const WithLayout = (props: PropsWithChildren) => {
  const { isCheckInstallationsBreadcrumb, setIsCheckInstallationsBreadcrumb, toasts, handleAddToastWithTimeout } =
    useOutletData()

  return (
    <>
      <NavigationBar
        isCheckInstallationsBreadcrumb={isCheckInstallationsBreadcrumb}
        setIsCheckInstallationsBreadcrumb={setIsCheckInstallationsBreadcrumb}
        toasts={toasts}
        handleAddToastWithTimeout={handleAddToastWithTimeout}
      />
      <div className={styles.siteContainer}>
        <main className={styles.main}>{props.children}</main>
        <Footer />
      </div>
    </>
  )
}

export const WithStubLayout = (props: PropsWithChildren) => {
  return (
    <>
      <NavigationBarStub />
      <div className={styles.siteContainer}>
        <main className={styles.main}>{props.children}</main>
        <Footer />
      </div>
    </>
  )
}
