import { MarkGithubIcon } from '@primer/octicons-react'
import { ExternalLinkIcon } from '@radix-ui/react-icons'
import { getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table'
import { useLocalStorage } from '@uidotdev/usehooks'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import { useParams } from 'react-router-dom'
import { match } from 'ts-pattern'
import { SeverityBadge } from '../components/severity-badge'
import { Spinner } from '../components/spinner'
import { Table } from '../components/table'
import { Tooltip } from '../components/tooltip'
import { utilities } from '../main.css.ts'
import { useThemeContext } from '../utils/use-theme'
import {
  useGetFindings,
  useGetInstallations,
  useGetRepositories,
  useGetScanAnalysis,
} from '../utils/user-platform-api-hooks'
import { Finding, ScanAnalysis, Tool } from '../utils/user-platform-api-schemas'
import * as styles from './analysis-details-page.css'

export function AnalysisDetailsPage() {
  const { installationId, repositoryId, analysisId, scanId } = useParams()
  if (!installationId || !repositoryId || !analysisId || !scanId) {
    throw new Error('Missing required parameters: installationId, repositoryId, analysisId, or scanId')
  }

  const { data: installations } = useGetInstallations()
  const installation = installations?.find(installation => installation.id === Number(installationId))
  const { data: repositories } = useGetRepositories(installation?.id ? [installation.id] : [])
  const { data: scanAnalysis } = useGetScanAnalysis({ analysisId, scanId })
  const { data: findings } = useGetFindings({ analysisId, scanId })
  const repository = repositories?.find(repo => repo.id === Number(repositoryId))

  return (
    <AnalysisDetailsPagePure
      accountLogin={installation?.account.login}
      repositoryName={repository?.name}
      scanAnalysis={scanAnalysis}
      findings={findings}
    />
  )
}

export function AnalysisDetailsPagePure({
  accountLogin,
  repositoryName,
  scanAnalysis,
  findings,
}: {
  accountLogin?: string
  repositoryName?: string
  scanAnalysis?: ScanAnalysis
  findings?: Finding[]
}) {
  const fixesCount = findings?.filter(finding => finding.fix_id !== null).length
  const totalFindings = findings?.length
  const { theme } = useThemeContext()

  return (
    <div className={styles.analysisDetailsContainer}>
      <header className={styles.analysisDetailsHeader} role="banner">
        {scanAnalysis ? (
          <ScanInfo scanAnalysis={scanAnalysis} theme={theme} />
        ) : (
          <Spinner label="Loading scan analysis..." />
        )}
        <section className={styles.repoInfoContainer} aria-labelledby="repo-info-header">
          <h2 id="repo-info-header" className={utilities.visuallyHidden}>
            Repository Information
          </h2>
          {accountLogin && repositoryName ? (
            <RepoAndOwnerInfo accountLogin={accountLogin} repositoryName={repositoryName} />
          ) : (
            <Spinner label="Loading user and repository details..." />
          )}
          {findings && typeof fixesCount === 'number' && typeof totalFindings === 'number' && scanAnalysis?.tool && (
            <FindingsBar fixesCount={fixesCount} totalFindings={totalFindings} tool={scanAnalysis.tool} />
          )}
        </section>
        <section className={styles.snykLinkContainer} role="complementary" aria-labelledby="metrics-header">
          <h2 id="metrics-header" className={utilities.visuallyHidden}>
            Metrics and External Link
          </h2>
          {scanAnalysis?.html_url && (
            <a href={scanAnalysis?.html_url} target="_blank" rel="noopener noreferrer" className={styles.snykLink}>
              View in {mapToolToLogoAndName(scanAnalysis.tool, theme).name}
              <ExternalLinkIcon className={styles.externalLinkIcon} />
            </a>
          )}
          {findings && typeof fixesCount === 'number' && typeof totalFindings === 'number' && (
            <FindingsMetrics fixesCount={fixesCount} totalFindings={totalFindings} />
          )}
        </section>
      </header>
      <FindingsTable findings={findings} />
    </div>
  )
}

function FindingsTable({ findings }: { findings?: Finding[] }) {
  const [pagination, setPagination] = useLocalStorage('ui-state/findings-table-pagination', {
    pageIndex: 0,
    pageSize: 10,
  })

  const table = useReactTable<Finding>({
    data: findings ?? [],
    columns: [
      {
        accessorKey: 'severity',
        meta: { width: '5%' },
        header: () => <span className={styles.header}>SEVERITY</span>,
        cell: ({ row }) => (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <SeverityBadge variant={row.original.severity} />
          </div>
        ),
      },
      {
        accessorKey: 'title',
        meta: { width: '30%' },
        header: () => (
          <span className={styles.header} style={{ justifyContent: 'flex-start' }}>
            FINDING
          </span>
        ),
        cell: ({ row }) => (
          <>
            <p className={styles.findingTitle}>
              {row.original.title.length > 52 ? row.original.title.slice(0, 49) + '...' : row.original.title}
            </p>
            <p className={styles.findingRule}>{row.original.rule}</p>
          </>
        ),
      },
      {
        accessorKey: 'suggestedStatus',
        meta: { width: '14%' },
        header: () => (
          <span className={classNames(styles.header, styles.grayText)}>
            SUGGESTED STATUS{' '}
            <Tooltip>
              <p className={styles.tooltipTextHeader}>Coming soon</p>
              <p className={styles.tooltipText}>Stay tuned for Triage analysis on scan results</p>
            </Tooltip>
          </span>
        ),
        cell: () => <span className={styles.placeholderCell}>-</span>,
      },
      {
        accessorKey: 'severityUpdate',
        meta: { width: '14%' },
        header: () => (
          <span className={classNames(styles.header, styles.grayText)}>
            SEVERITY UPDATE{' '}
            <Tooltip>
              <p className={styles.tooltipTextHeader}>Coming soon</p>
              <p className={styles.tooltipText}>Stay tuned for Triage analysis on scan results</p>
            </Tooltip>
          </span>
        ),
        cell: () => <span className={styles.placeholderCell}>-</span>,
      },
      {
        accessorKey: 'summary',
        meta: { width: '14%' },
        header: () => (
          <span className={classNames(styles.header, styles.grayText)}>
            SUMMARY{' '}
            <Tooltip>
              <p className={styles.tooltipTextHeader}>Coming soon</p>
              <p className={styles.tooltipText}>Stay tuned for Triage analysis on scan results</p>
            </Tooltip>
          </span>
        ),
        cell: () => <span className={styles.placeholderCell}>-</span>,
      },
      {
        accessorKey: 'fix',
        meta: { width: '10%' },
        header: () => <span className={styles.header}>FIX</span>,
        cell: ({ row }) =>
          row.original.fix_id ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <button className={styles.fixPreviewButton} onClick={e => e.preventDefault()}>
                Preview
              </button>
            </div>
          ) : (
            <span className={styles.placeholderCell}>-</span>
          ),
      },
    ],
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      pagination,
    },
  })

  return (
    <Table
      table={table}
      isLoading={findings === undefined}
      pagination={pagination}
      setPagination={setPagination}
      tableLabel="Findings"
    />
  )
}

export const mapToolToLogoAndName = (tool: Tool, mode: 'light' | 'dark') => {
  const suffix = mode === 'light' ? 'Lt' : 'Dk'
  return match(tool)
    .with('codeql', () => ({ name: 'CodeQL', logoHref: '/SastToolVisualAssets/CodeQL-Logo.svg' }))
    .with('sonar', () => ({ name: 'SonarQube', logoHref: `/SastToolVisualAssets/Sonar-Icon-${suffix}.svg` }))
    .with('sonar_issues', () => ({
      name: 'SonarQube Issues',
      logoHref: `/SastToolVisualAssets/Sonar-Icon-${suffix}.svg`,
    }))
    .with('sonar_hotspots', () => ({
      name: 'SonarQube Hotspots',
      logoHref: `/SastToolVisualAssets/Sonar-Icon-${suffix}.svg`,
    }))
    .with('semgrep', () => ({ name: 'Semgrep', logoHref: '/SastToolVisualAssets/Semgrep-Icon.svg' }))
    .with('appscan', () => ({ name: 'AppScan', logoHref: '/SastToolVisualAssets/AppScan-Icon.svg' }))
    .with('defectdojo', () => ({ name: 'DefectDojo', logoHref: '/SastToolVisualAssets/DefectDojo-Icon.svg' }))
    .with('contrast', () => ({ name: 'Contrast', logoHref: `/SastToolVisualAssets/Contrast-Icon-${suffix}.svg` }))
    .with('snyk', () => ({ name: 'Snyk', logoHref: `/SastToolVisualAssets/Snyk-Icon-${suffix}.svg` }))
    .with('checkmarx', () => ({ name: 'Checkmarx', logoHref: '/SastToolVisualAssets/Checkmarx-Icon.svg' }))
    .exhaustive()
}

const ScanInfo = ({ scanAnalysis, theme }: { scanAnalysis: ScanAnalysis; theme: 'light' | 'dark' }) => {
  const { name, logoHref } = mapToolToLogoAndName(scanAnalysis.tool, theme)
  const scanDate = DateTime.fromISO(scanAnalysis.uploaded_at)

  return (
    <div className={styles.scanInfoContainer}>
      <h2 className={utilities.visuallyHidden}>Scan Information</h2>
      <img src={logoHref} alt={`${name} logo`} className={styles.toolLogo} />
      <div className={styles.dateContainer}>
        <p className={styles.dateText}>{scanDate.toFormat('M/d/yy')}</p>
        <p className={styles.timeText}>{scanDate.toFormat('hh:mm:ss a')}</p>
      </div>
      <p className={styles.branchTag} title={scanAnalysis.branch}>
        {scanAnalysis.branch.length > 25 ? `${scanAnalysis.branch.slice(0, 22)}...` : scanAnalysis.branch}
      </p>
    </div>
  )
}

const RepoAndOwnerInfo = ({ accountLogin, repositoryName }: { accountLogin: string; repositoryName: string }) => (
  <>
    <p className={styles.accountLoginText}>{accountLogin}</p>
    <p className={styles.repoNameContainer}>
      <MarkGithubIcon className={styles.scmIcon} />
      {repositoryName}
    </p>
  </>
)

const FindingsBar = ({
  fixesCount,
  totalFindings,
  tool,
}: {
  fixesCount: number
  totalFindings: number
  tool: Tool
}) => (
  <div
    role="img"
    aria-label={`${fixesCount} out of ${totalFindings} findings had available fixes`}
    className={styles.fixesContainer}
  >
    <div className={styles.bar}>
      {fixesCount === 0 ? (
        <div
          className={classNames(styles.barFilled[tool], styles.barEmpty, styles.roundedBar)}
          style={{ width: '100%' }}
        />
      ) : fixesCount === totalFindings ? (
        <div className={classNames(styles.barFilled[tool], styles.roundedBar)} style={{ width: '100%' }} />
      ) : (
        <>
          <div className={styles.barFilled[tool]} style={{ width: `${(fixesCount / totalFindings) * 100}%` }} />
          <div
            className={classNames(styles.barFilled[tool], styles.barEmpty)}
            style={{ width: `${((totalFindings - fixesCount) / totalFindings) * 100}%` }}
          />
        </>
      )}
    </div>
    <p className={styles.fixesText}>
      {fixesCount}/{totalFindings} <span className={styles.fixesTextLabel}>findings had available fixes</span>
    </p>
  </div>
)

const FindingsMetrics = ({ fixesCount, totalFindings }: { fixesCount: number; totalFindings: number }) => (
  <div className={styles.metricsContainer} role="group" aria-label="Findings Metrics">
    <output id="findings-count" className={styles.metricValue}>
      {totalFindings}
    </output>
    <label htmlFor="findings-count" className={styles.metricLabel}>
      findings
    </label>
    <output id="fix-coverage" className={styles.metricValue}>
      {totalFindings === 0 ? '0%' : `${Math.round((fixesCount / totalFindings) * 100)}%`}
    </output>
    <label htmlFor="fix-coverage" className={styles.metricLabel}>
      fix coverage
    </label>
  </div>
)
