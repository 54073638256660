import { useThemeContext } from '../utils/use-theme'
import { PullRequest } from '../utils/user-platform-api-schemas'
import './recent-activity-table.css'
import styles from './recent-activity-table.module.css'
import { GithubIcon } from './svg/github'
import PullRequestClosed from './svg/pull-request-closed'
import PullRequestClosedBlack from './svg/pull-request-closed-black'
import PullRequestMerged from './svg/pull-request-merged'
import PullRequestMergedBlack from './svg/pull-request-merged-black'
import PullRequestOpen from './svg/pull-request-open'
import PullRequestOpenBlack from './svg/pull-request-open-black'
import { TableBodySkeleton } from './table-body-skeleton'

type RecentActivityTableProps = {
  pixeebotPRs?: PullRequest[]
}

export function RecentActivityTable({ pixeebotPRs }: RecentActivityTableProps) {
  const { theme } = useThemeContext()

  return (
    <>
      <h2 className="h-4 pr-table-header">Recent activity</h2>
      <table className={`table table-borderless table-hover table-striped-custom ${styles.table}`}>
        <thead>
          <tr>
            <th className="column-header" scope="col" style={{ paddingLeft: '24px' }}>
              TITLE
            </th>
            <th className="column-header" scope="col">
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <GithubIcon theme={theme} />
                <span style={{ paddingLeft: '8px', paddingRight: '10px' }}>STATUS</span>
              </span>
            </th>
          </tr>
        </thead>
        {!pixeebotPRs ? (
          <TableBodySkeleton rowCount={7} columnCount={2} rowHeightInPixels={64} />
        ) : pixeebotPRs.length === 0 ? (
          <tbody>
            <tr className={`${styles.emptyStateRow}`} key={0}>
              <td colSpan={2}>
                <span
                  className={`d-flex flex-row justify-content-center align-items-center paragraph-italic ${styles.emptyStateSpan}`}
                >
                  No recent PRs
                </span>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {pixeebotPRs.map(pullRequest => (
              <PRRow key={pullRequest.id} pullRequest={pullRequest} />
            ))}
          </tbody>
        )}
      </table>
    </>
  )
}

type PRRowProps = {
  pullRequest: PullRequest
}

const PRRow = ({ pullRequest }: PRRowProps) => {
  const { theme } = useThemeContext()

  return (
    <tr>
      <td scope="row" style={{ paddingLeft: '24px' }}>
        <b>
          <a
            href={pullRequest.html_url}
            target="_blank"
            className={`paragraph-bold title-text ${styles.pullRequestTitle}`}
          >
            {pullRequest.title}
          </a>
        </b>
        {' - '}
        <b>
          <span>
            <a
              href={pullRequest.repository_url.replace('api.github.com/repos', 'github.com')}
              target="_blank"
              className={`paragraph-bold ${styles.pullRequestLocation}`}
              style={{ color: theme == 'dark' ? 'var(--neutral30)' : 'var(--neutral50)' }}
            >
              {`(${pullRequest.repository_url.split('/')[4]} / ${pullRequest.repository_url.split('/')[5]})`}
            </a>
          </span>
        </b>
        <br />
        <span
          className=" text-decoration-none small"
          style={{ color: theme == 'dark' ? 'var(--neutral30)' : 'var(--neutral90)' }}
        >
          #{pullRequest.number}
        </span>{' '}
        <span className="small" style={{ color: theme == 'dark' ? 'var(--neutral30)' : 'var(--neutral90)' }}>
          opened {relativeTime(pullRequest.created_at)}
        </span>
      </td>
      <td scope="row">
        {pullRequest.state === 'open' ? (
          <a href={pullRequest.html_url} target="_blank">
            <span className="badge extra-small-bold open-badge">
              {theme === 'dark' ? <PullRequestOpen /> : <PullRequestOpenBlack />} Open
            </span>
          </a>
        ) : pullRequest.pull_request !== undefined && pullRequest.pull_request.merged_at !== null ? (
          <a href={pullRequest.html_url} target="_blank">
            <span className="badge extra-small-bold merged-badge">
              {theme === 'dark' ? <PullRequestMerged /> : <PullRequestMergedBlack />}
              Merged
            </span>
          </a>
        ) : (
          <a href={pullRequest.html_url} target="_blank">
            <span className="badge extra-small-bold merged-badge closed-badge">
              {theme === 'dark' ? <PullRequestClosed /> : <PullRequestClosedBlack />} Closed
            </span>
          </a>
        )}
      </td>
    </tr>
  )
}

function relativeTime(timestamp) {
  const now = new Date().getTime()
  const secondsAgo = Math.floor((now - new Date(timestamp).getTime()) / 1000)

  if (secondsAgo < 60) {
    return `${secondsAgo} seconds ago`
  } else if (secondsAgo < 3600) {
    const minutesAgo = Math.floor(secondsAgo / 60)
    return `${minutesAgo} minute${minutesAgo === 1 ? '' : 's'} ago`
  } else if (secondsAgo < 86400) {
    const hoursAgo = Math.floor(secondsAgo / 3600)
    return `${hoursAgo} hour${hoursAgo === 1 ? '' : 's'} ago`
  } else {
    const daysAgo = Math.floor(secondsAgo / 86400)
    return `${daysAgo} day${daysAgo === 1 ? '' : 's'} ago`
  }
}
