import * as Sentry from '@sentry/react'
import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import { createRoot } from 'react-dom/client'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Navigate,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import invariant from 'tiny-invariant'
import { match } from 'ts-pattern'
import './main.css'
import { RouteKey, Routes } from './routes'
import { AccountPage } from './routes/account-page'
import { AnalysisDetailsPage } from './routes/analysis-details-page'
import { DeveloperDashboardPage } from './routes/developer-dashboard-page'
import { ErrorPage } from './routes/error-page'
import { GenerateSingleFixPage } from './routes/generate-single-fix-page'
import { InstallationsPage } from './routes/installations-page'
import { LoginPage } from './routes/login-page'
import { RepositoryPage } from './routes/repository-page'
import { Root } from './routes/root'
import { WithLayout, WithStubLayout } from './utils/withLayout'

import.meta.env.PROD &&
  !import.meta.env.MOCKS &&
  Sentry.init({
    dsn: 'https://3e148ebf8aefc7d69909903b8ada4f76@o4505326209073152.ingest.us.sentry.io/4505940512342016',
    environment: window.location.host,
    integrations: [
      Sentry.captureConsoleIntegration({
        levels: ['error'],
      }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/\/api\//],
  })

async function enableMocking() {
  if (!import.meta.env.MOCKS) return

  console.log('Setting cookies')
  const { createId } = await import('@paralleldrive/cuid2')
  const { DateTime } = await import('luxon')
  document.cookie = `q_session=${createId()}; Path=/`
  document.cookie = `github_refresh_token_expires_at=${DateTime.now().plus({ months: 6 }).toISO()}; Path=/`

  console.log('Enabling mocks')
  const worker = await Promise.all([import('msw/browser'), import('../playwright/handlers')]).then(
    ([{ setupWorker }, { handlers }]) => {
      console.log('Setting up worker')
      return setupWorker(...handlers)
    }
  )

  console.log('Starting worker')
  return worker.start({ onUnhandledRequest: 'bypass' })
}

enableMocking().then(() => {
  const container = document.getElementById('root')
  invariant(container)
  const root = createRoot(container)

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Root />,
      errorElement: <ErrorPage />,
      children: Object.keys(Routes).map(route =>
        match<RouteKey>(route as RouteKey)
          .with('LoginPage', key => ({
            path: Routes[key].path,
            element: (
              <WithStubLayout>
                <LoginPage />
              </WithStubLayout>
            ),
          }))
          .with('IntroPage', key => ({
            path: Routes[key].path,
            element: <Navigate to={Routes.DeveloperDashboardPage.path} replace />,
          }))
          .with('WelcomePage', key => ({
            path: Routes[key].path,
            element: <Navigate to={Routes.DeveloperDashboardPage.path} replace />,
          }))
          .with('Installations', key => ({
            path: Routes[key].path,
            element: (
              <WithLayout>
                <InstallationsPage />
              </WithLayout>
            ),
          }))
          .with('AccountPage', key => ({
            path: Routes[key].path,
            element: (
              <WithLayout>
                <AccountPage />
              </WithLayout>
            ),
          }))
          .with('RepositoryPage', key => ({
            path: Routes[key].path,
            element: (
              <WithLayout>
                <RepositoryPage />
              </WithLayout>
            ),
          }))
          .with('DeveloperDashboardPage', key => ({
            path: Routes[key].path,
            element: (
              <WithLayout>
                <DeveloperDashboardPage />
              </WithLayout>
            ),
          }))
          .with('DeveloperDashboardAccountPage', key => ({
            path: Routes[key].path,
            element: (
              <WithLayout>
                <DeveloperDashboardPage />
              </WithLayout>
            ),
          }))
          .with('DeveloperDashboardRepositoryPage', key => ({
            path: Routes[key].path,
            element: (
              <WithLayout>
                <DeveloperDashboardPage />
              </WithLayout>
            ),
          }))
          .with('GenerateSingleFixPage', key => ({
            path: Routes[key].path,
            element: (
              <WithLayout>
                <GenerateSingleFixPage />
              </WithLayout>
            ),
          }))
          .with('AnalysisDetailsPage', key => ({
            path: Routes[key].path,
            element: (
              <WithLayout>
                <AnalysisDetailsPage />
              </WithLayout>
            ),
          }))
          .exhaustive()
      ),
    },
  ])

  root.render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  )
})
